import { combineReducers } from "redux";
import { clientesReducer, clientesStatusReducer } from "./redux/clientes";
import { devolucionesReducer, devolucionesStatusReducer } from "./redux/devoluciones";
import { estadosIncidenciaFullfilledReducer } from "./redux/estadosIncidencia";
import { estadosVentaFullfilledReducer } from "./redux/estadosVenta";
import { facturasReducer, facturasStatusReducer } from "./redux/facturas";
import { familiaPiezaFullfilledReducer } from "./redux/familiaPiezas";
import { incidenciasReducer, incidenciasStatusReducer } from "./redux/incidencias";
import { marcasFullfilledReducer } from "./redux/marcas";
import { modelosFullfilledReducer } from "./redux/modelos";
import { paisesFullfilledReducer } from "./redux/paises";
import { plataformasFullfilledReducer } from "./redux/plataformas";
import { poblacionesFullfilledReducer } from "./redux/poblaciones";
import { presupuestosReducer, presupuestosStatusReducer } from "./redux/presupuestos";
import { proveedoresReducer, proveedoresStatusReducer } from "./redux/proveedores";
import { rolesFullfilledReducer } from "./redux/roles";
import { searchReducer } from "./redux/features/search";
import { tipoClientesFullfilledReducer } from "./redux/tipoClientes";
import { tipoClientesB2bFullfilledReducer } from "./redux/tipoClientesB2b";
import { tipoDevolucionesFullfilledReducer } from "./redux/tipoDevoluciones";
import { tipoFacturasFullfilledReducer } from "./redux/tipoFacturas";
import { tipoGarantiasFullfilledReducer } from "./redux/tipoGarantias";
import { tipoIncidenciasFullfilledReducer } from "./redux/tipoIncidencias";
import { tipoPiezasFullfilledReducer } from "./redux/tipoPiezas";
import { usersReducer, usersStatusReducer } from "./redux/users";
import { ventasReducer, ventasStatusReducer } from "./redux/ventas";
import { avisosReducer, avisosStatusReducer } from "./redux/avisos";
import { fichaIncidenciaReducer, currentIncidenciaStatusReducer } from "./redux/features/fichaIncidencia";
import { dashboardReducer, dashboardStatusReducer } from "./redux/features/dashboard";
import { currentClienteReducer, currentClienteStatusReducer } from "./redux/features/currentCliente";
import { currentUserReducer, currentUserStatusReducer } from "./redux/features/currentUser";
import { currentPiezaReducer } from "./redux/features/currentPieza";
import { currentProveedorReducer } from "./redux/features/currentProveedor";
import { fichaClienteReducer, fichaClienteStatusReducer } from "./redux/features/fichaCliente";
import { fichaProveedorReducer, fichaProveedorStatusReducer } from "./redux/features/fichaProveedor";
import { tarifasFullfilledReducer } from "./redux/tarifas";
import { stepsFullfilledReducer } from "./redux/incidenciaSteps";
import { avisosListReducer, avisosListStatusReducer } from "./redux/features/avisosList";
import { administracionFullfilledReducer, atencionClienteFullfilledReducer, comercialesFullfilledReducer, recambistasFullfilledReducer,  } from "./redux/features/tipoUsers";
import { readOnlyDataReducer, readOnlyDataStatusReducer } from "./redux/readOnly";
import { notificacionesReducer, notificacionesStatusReducer } from "./redux/features/notificaciones";
import { serviciosEnvioFullfilledReducer } from "./redux/serviciosEnvio";
import { transportistasFullfilledReducer } from "./redux/transportistas";
import { enviosReducer, enviosStatusReducer } from "./redux/envios";
import { checkoutReducer, checkoutStatusReducer } from "./redux/features/checkout";
import { finanzasReducer, finanzasStatusReducer } from "./redux/features/finanzas";
import { comercialDetailReducer, comercialDetailStatusReducer } from "./redux/comercial_detail";
import { fichaPresupuestoReducer, fichaPresupuestoStatusReducer } from "./redux/features/fichaPresupuesto";
import { subestadosIncidenciaFullfilledReducer } from "./redux/subestadosIncidencia";
import { recordatoriosClientesReducer, recordatoriosClientesStatusReducer } from "./redux/features/recordatoriosClientes";
import { facturasDesguacesReducer, facturasDesguacesStatusReducer } from "./redux/facturasDesguaces";
import { loadingReducer } from "./redux/features/loading";
import { dashboardVehiculosReducer, dashboardVehiculosStatusReducer } from "./redux/features/vehiculos";
import { devolucionesMercanciasReducer, devolucionesMercanciasStatusReducer } from "./redux/devoluciones_mercancias";
import { estadosPiezasFullfilledReducer } from "./redux/estadosPiezas";
import { desguacesFullfilledReducer } from "./redux/desguaces";

const reducer = combineReducers({
    // UTILITIES
    search: searchReducer,
    // DATA LIST
    avisos: combineReducers({
        entities: avisosReducer,
        status: avisosStatusReducer
    }),
    clientes: combineReducers({
        entities: clientesReducer,
        status: clientesStatusReducer,
    }),
    presupuestos: combineReducers({
        entities: presupuestosReducer,
        status: presupuestosStatusReducer,
    }),
    envios: combineReducers({
        entities: enviosReducer,
        status: enviosStatusReducer,
    }),
    devoluciones: combineReducers({
        entities: devolucionesReducer,
        status: devolucionesStatusReducer,
    }),
    devolucion_mercancias: combineReducers({
        entities: devolucionesMercanciasReducer,
        status: devolucionesMercanciasStatusReducer,
    }),
    facturas: combineReducers({
        entities: facturasReducer,
        status: facturasStatusReducer,
    }),
    facturas_desguaces: combineReducers({
        entities: facturasDesguacesReducer,
        status: facturasDesguacesStatusReducer,
    }),
    incidencias: combineReducers({
        entities: incidenciasReducer,
        status: incidenciasStatusReducer,
    }),
    notificaciones: combineReducers({
        entities: notificacionesReducer,
        status: notificacionesStatusReducer,
    }),
    proveedores: combineReducers({
        entities: proveedoresReducer,
        status: proveedoresStatusReducer,
    }),
    detalles: combineReducers({
        entities: comercialDetailReducer,
        status: comercialDetailStatusReducer,
    }),
    users: combineReducers({
        entities: usersReducer,
        status: usersStatusReducer,
    }),
    ventas: combineReducers({
        entities: ventasReducer,
        status: ventasStatusReducer,
    }),
    // READ ONLY DATA
    read_only: combineReducers({
        entities: readOnlyDataReducer,
        status: readOnlyDataStatusReducer,
    }),
    //-------------------------------
    tipo_clientes: tipoClientesFullfilledReducer,
    tipo_clientes_b2b: tipoClientesB2bFullfilledReducer,
    poblaciones: poblacionesFullfilledReducer,
    paises: paisesFullfilledReducer,
    roles: rolesFullfilledReducer,
    estados_venta: estadosVentaFullfilledReducer,
    estados_incidencia: estadosIncidenciaFullfilledReducer,
    subestados_incidencia: subestadosIncidenciaFullfilledReducer,
    tipo_facturas: tipoFacturasFullfilledReducer,
    tipo_piezas: tipoPiezasFullfilledReducer,
    familia_piezas: familiaPiezaFullfilledReducer,
    tipo_garantias: tipoGarantiasFullfilledReducer,
    tipo_devoluciones: tipoDevolucionesFullfilledReducer,
    tipo_incidencias: tipoIncidenciasFullfilledReducer,
    marcas: marcasFullfilledReducer,
    modelos: modelosFullfilledReducer,
    plataformas: plataformasFullfilledReducer,
    tarifas: tarifasFullfilledReducer,
    steps: stepsFullfilledReducer,
    transportistas: transportistasFullfilledReducer,
    servicios_envio: serviciosEnvioFullfilledReducer,
    recambistas: recambistasFullfilledReducer,
    comerciales: comercialesFullfilledReducer,
    atencion_cliente: atencionClienteFullfilledReducer,
    administracion: administracionFullfilledReducer,
    estados_piezas: estadosPiezasFullfilledReducer,
    desguaces: desguacesFullfilledReducer,
    //-------------------------------
    // CURRENT DATA
    loading: loadingReducer,
    dashboard: combineReducers({
        entity: dashboardReducer,
        status: dashboardStatusReducer
    }),
    vehiculos: combineReducers({
        entities: dashboardVehiculosReducer,
        status: dashboardVehiculosStatusReducer
    }),
    presupuesto: combineReducers({
        entity: fichaPresupuestoReducer,
        status: fichaPresupuestoStatusReducer
    }),
    currentIncidencia: combineReducers({
        entity: fichaIncidenciaReducer,
        status: currentIncidenciaStatusReducer
    }),
    currentCliente: combineReducers({
        entity: currentClienteReducer,
        status: currentClienteStatusReducer
    }),
    currentUser: combineReducers({
        entity: currentUserReducer,
        status: currentUserStatusReducer
    }),
    currentPieza: currentPiezaReducer,
    currentProveedor: currentProveedorReducer,
    fichaCliente: combineReducers({
        entity: fichaClienteReducer,
        status: fichaClienteStatusReducer
    }),
    fichaProveedor: combineReducers({
        entity: fichaProveedorReducer,
        status: fichaProveedorStatusReducer
    }),
    avisosList: combineReducers({
        entity: avisosListReducer,
        status: avisosListStatusReducer
    }),
    checkout: combineReducers({
        entity: checkoutReducer,
        status: checkoutStatusReducer
    }),
    finanzas: combineReducers({
        entity: finanzasReducer,
        status: finanzasStatusReducer,
    }),
    recordatorios: combineReducers({
        entities: recordatoriosClientesReducer,
        status: recordatoriosClientesStatusReducer
    })
})

const rootReducer = reducer;

export default rootReducer;